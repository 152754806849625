/* ---------- Price Tabels ---------- */
/* ---------- Price Box Round Symbol Css ---------- */
.box_round_symbol {
    background-color: var(--danger);
    text-align: center;
    font-size: 40px;
    color: #fff;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    padding: 21px 1px 1px 0px;
    margin: auto;
}
/* ---------- //Price Box Round Symbol Css End---------- */
